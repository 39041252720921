import { VariantProps, cva } from "class-variance-authority";
import { Slot } from "@radix-ui/react-slot";
import { forwardRef } from "react";
import { cn } from "../../utils/cn";

export const textVariants = cva("", {
  variants: {
    variant: {
      "site-headline": "fsc-site-headline",
      "section-headline": "fsc-section-headline",
      "section-headline-sm": "fsc-section-headline-sm",
      "body-xl": "fsc-body-xl",
      "body-lg": "fsc-body-lg",
      "blog-highlight": "fsc-blog-highlight",
      blog: "fsc-blog",
      body: "fsc-body",
      button: "fsc-button",
      caption: "fsc-caption",
    },
  },
  defaultVariants: {
    variant: "body",
  },
});

export const textOverrides = cva("", {
  variants: {
    weight: {
      light: "font-light",
      normal: "font-normal",
      medium: "font-medium",
      semi: "font-semibold",
      bold: "font-bold",
      inherit: "font-inherit",
    },
    size: {
      default: "text-base md:text-lg lg:text-xl",
      xs: "text-xs md:text-sm",
      sm: "text-sm md:text-base",
      base: "text-base md:text-lg",
      lg: "text-lg md:text-xl",
      xl: "text-xl md:text-2xl",
      "2xl": "text-2xl md:text-3xl",
      "3xl": "text-3xl md:text-4xl",
      inherit: "text-inherit",
    },
    color: {
      "text-primary": "text-text-primary",
      "text-secondary": "text-text-secondary",
      "text-tertiary": "text-text-tertiary",
      "text-white": "text-text-white",
      "text-black": "text-text-black",
      "text-contrast": "text-text-contrast",
      "text-disabled": "text-text-disabled",
      "text-placeholder": "text-text-placeholder",
      "text-error": "text-text-error",
      "text-warning": "text-text-warning",
      "text-success": "text-text-success",
      "brand-1": "text-brand-1",
      "brand-2": "text-brand-2",
      "brand-3": "text-brand-3",
      "brand-4": "text-brand-4",
      "brand-5": "text-brand-5",
      "chains-primary": "text-chains-primary",
      "chains-secondary": "text-chains-secondary",
      "chains-tertiary": "text-chains-tertiary",
      "analysts-primary": "text-analysts-primary",
      "analysts-secondary": "text-analysts-secondary",
      "analysts-tertiary": "text-analysts-tertiary",
      "questors-primary": "text-questors-primary",
      "questors-secondary": "text-questors-secondary",
      "questors-tertiary": "text-questors-tertiary",
      "fg-warning": "text-fg-warning",
      "fg-success": "text-fg-success",
      "fg-primary": "text-fg-primary",
      "fg-secondary": "text-fg-secondary",
      "fg-tertiary": "text-fg-tertiary",
      "fg-white": "text-fg-white",
      "fg-black": "text-fg-black",
      "fg-disabled": "text-fg-disabled",
      "fg-error": "text-fg-error",
    },
  },
  defaultVariants: {
    color: "text-primary",
  },
});

export interface TextProps
  extends VariantProps<typeof textVariants>,
    VariantProps<typeof textOverrides>,
    Omit<React.HTMLAttributes<HTMLDivElement>, "color"> {
  asChild?: boolean;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "span" | "dt" | "dd";
  variant?: VariantProps<typeof textVariants>["variant"];
  weight?: VariantProps<typeof textOverrides>["weight"];
  size?: VariantProps<typeof textOverrides>["size"];
  color?:
    | "text-primary"
    | "text-secondary"
    | "text-tertiary"
    | "text-white"
    | "text-black"
    | "text-disabled"
    | "text-placeholder"
    | "text-error"
    | "text-warning"
    | "text-success"
    | "text-contrast"
    | "brand-1"
    | "brand-2"
    | "brand-3"
    | "brand-4"
    | "brand-5"
    | "chains-primary"
    | "chains-secondary"
    | "chains-tertiary"
    | "analysts-primary"
    | "analysts-secondary"
    | "analysts-tertiary"
    | "questors-primary"
    | "questors-secondary"
    | "questors-tertiary"
    | "fg-warning"
    | "fg-success"
    | "fg-primary"
    | "fg-secondary"
    | "fg-tertiary"
    | "fg-white"
    | "fg-black"
    | "fg-disabled"
    | "fg-error";
}

export const Text = forwardRef<HTMLDivElement, TextProps>(
  ({ as = "span", asChild, variant, weight, color, size, className, ...props }: TextProps, ref) => {
    const Comp = asChild ? Slot : as;
    const variantClasses = textVariants({ variant });
    const overrideClasses = textOverrides({ weight, color, size });
    return <Comp ref={ref} className={cn(variantClasses, overrideClasses, className)} {...props} />;
  },
);

Text.displayName = "Text";
