import clsx from "clsx";

export function LogoIcon({ className, ...props }: JSX.IntrinsicElements["svg"]) {
  return (
    <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8207 1.34383L2.73481 6.58966C1.37238 7.37623 0.532959 8.83013 0.532959 10.4033V20.8956C0.532959 22.4688 1.3717 23.922 2.73414 24.7085L13.9527 31.1901L14.0124 17.9661C14.0191 16.5312 14.7867 15.2081 16.0293 14.4906L27.5121 7.86064L16.2244 1.34383C14.8619 0.557264 13.1831 0.557264 11.8207 1.34383ZM16.2704 22.1393V19.113C16.2704 17.7729 16.9858 16.5351 18.147 15.8671L27.5439 10.4573L27.5276 13.1253C27.5181 14.6889 26.6801 16.13 25.3258 16.9118L16.2704 22.14V22.1393ZM16.2704 26.8976V29.9266L25.3095 24.7079C26.6719 23.9213 27.5114 22.4674 27.5114 20.8943V18.2446L18.143 23.6537C16.9845 24.3231 16.2704 25.5595 16.2704 26.8976Z"
        className={clsx("fill-current", className)}
      />
    </svg>
  );
}
